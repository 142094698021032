<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header column">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.import.file") }}</label>
            <input type="file" name="" id=""> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.import.format") }}</label>
            <select name="" id="">
              <option value="" selected>Autodetectar</option>  
              <option value="">INE</option>  
              <option value="">RTC</option>  
            </select> 
          </div>
        </div>
      </div>
    </div>
    <div class="workspace__container__view__actions">
      <button>{{ $t("workspace.import.import") }}</button>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  setup() {
    const filterModel = ref({
      code: '',
      name: ''
    })

    return {
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>